<template>
  <section class="dtc-main-section">
    <section class="search-block">
      <h5 class="big-title">個人資料維護</h5>
      <div class="main-content">
        <b-input-group
          prepend="身分證字號"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="身分證字號" readonly></b-form-input>
        </b-input-group>
        <b-input-group prepend="姓名" class="mb-2 mr-sm-2 mb-sm-0 row-content">
          <b-form-input placeholder="姓名" readonly></b-form-input>
        </b-input-group>
        <b-input-group prepend="性別" class="mb-2 mr-sm-2 mb-sm-0 row-content">
          <b-form-input placeholder="性別" readonly></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="出生日期"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="出生日期" readonly></b-form-input>
        </b-input-group>
        <b-input-group prepend="籍貫" class="mb-2 mr-sm-2 mb-sm-0 row-content">
          <b-form-input placeholder="籍貫" readonly></b-form-input>
        </b-input-group>
        <b-input-group prepend="籍屬" class="mb-2 mr-sm-2 mb-sm-0 row-content">
          <b-form-input placeholder="籍屬" readonly></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="戶籍地址"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="戶籍地址" readonly></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="通訊地址"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="輸入通訊地址"></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="連絡電話"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="輸入連絡電話"></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="行動電話"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="輸入行動電話"></b-form-input>
        </b-input-group>
        <b-input-group
          prepend="E-MAIL"
          class="mb-2 mr-sm-2 mb-sm-0 row-content"
        >
          <b-form-input placeholder="輸入E-MAIL"></b-form-input>
        </b-input-group>
      </div>
      <b-button
        variant="warning"
        class="float-right"
        style="margin-top:10px;margin-right:24px"
        >確認</b-button
      >
    </section>
  </section>
</template>

<script>
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  components: {},
  data() {
    return {
      items: [],
      keys: [],
    };
  },
  computed: {},

  methods: {
    editFile(item) {},
    async getData() {},
  },
  async mounted() {},

  watch: {},
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  background: #deeaf1;
  height: 100vh;
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #125fa3;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.search-block {
  width: 800px;
  height: 650px;
  margin: 15vh auto 0 auto;
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .main-content {
    padding: 0 20px;
    .row-content {
      margin-top: 12px;
    }
  }
}

.input-group-text {
  width: 120px;
  background: #0379fd;
  color: #fff;
}
</style>
